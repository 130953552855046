
import DatePicker from "../../../../components/Content/DatePicker.vue";
import {
	isNull,
	isUndefined,
	isEmpty
} from "lodash";
import Vue from "vue";
import {
	isRequired,
	isNumber,
	isAfterToday,
	isAfterCompare,
} from "../../../../services/rule-services";
//@ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import { mapActions, mapGetters } from "vuex";
// @ts-ignore
import { SortingOption } from "@/interfaces/paginated";
//@ts-ignore
import Filterable from "@/components/Header/Tables/Filterable.vue";
//@ts-ignore
import { getPermisionCreatives } from "@/utils/permissionResolve";

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
const DATE_FORMAT = "DD-MM-YYYY";

export default Vue.extend({
	name: "AssociationTable",
	props: {
		creative: {
			type: Object,
			default: function () {
				return {};
			},
		},
		headers: {
			type: Array,
			default: function () {
				return [];
			},
		},
		items: {
			type: Array,
			default: function () {
				return [];
			},
		},
		options: {
			type: Object,
			default: function () {
				return {};
			},
		},
		filters: {
			type: Object,
			default: function () {
				return {};
			},
		},
		current_page: {
			type: Number,
		},
		next_page_url: {
			type: String,
		},
		path: {
			type: String,
		},
		per_page: {
			type: Number,
		},
		prev_page_url: {
			type: String,
		},
		to: {
			type: Number,
		},
		total: {
			type: Number,
		}
	},
	components: {
		DatePicker,
		Filterable
	},
	data: () => ({
		start_date_rules: [],
		end_date_rules: []
	}),

	created() {},

	mounted() {},

	computed: {
		...mapGetters("profile", ["getAbility"]),
		getRules() {
			return {
				isRequired,
				isNumber,
				isAfterToday,
				isAfterCompare,
			};
		},

		getToday() {
			return this.moment().format(DATE_TIME_FORMAT);
		},

		getMinDate() {
			if (this.getCreative?.start_date) {
				return this.moment(this.getCreative?.start_date).format(
					DATE_TIME_FORMAT
				);
			}
			return undefined;
		},

		currentPage: {
			set(val) {
				this.$emit("update-current-page", val);
			},
			get() {
				return this.current_page;
			},
		},

		getCreative() {
			let result = this.$store.state.creative.creative;
			if ( !isNull(result) && !isUndefined(result) ) {
				return result;
			}
			return this.creative;
		},

		getLength() {
			return Math.ceil(this.total / this.per_page);
		},

		perPage() {
			return !isNaN(this.per_page) && isEmpty(this.per_page)
				? this.per_page
				: 25;
		},

		canDeleteItem(){
			return this.getAbility.can(this.getPermission.actions.delete_assoc, this.getPermission.subject);
		},

		canEditItem(){
			return this.getAbility.can(this.getPermission.actions.update_assoc, this.getPermission.subject);
		},

		getPermission(){
			return getPermisionCreatives();
		}
	},

	methods: {
		...mapActions("loading", ["setLoadingData"]),

		getColor(active: Boolean) {
			return active ? "green--text" : "red--text";
		},

		getActiveText(active: Boolean) {
			return active ? "Active" : "Inactive";
		},

		updatePaginate(data: Number) {
			this.$emit("update-paginate", data);
		},

		selectedOption(params: { options: SortingOption; filter: any }) {
			this.$emit("selected-option", {
				options: params.options,
				filter: params.filter,
			});
		},

		removeOption() {
			this.$emit("remove-option");
		},

		async deleteAssociation(id: number) {
			if ( isNull(id) || isUndefined(id) ) {return false;}
			try {
				await (this as any).setLoadingData(TypeLoading.loading);
				await (this as any).$store.dispatch(
					"creative/associateLineItemDelete",
					id
				).then( (result) => {
					(this as any).dispatchShowLineItem((this as any).getCreative?.id);
				},
				(error) => {
					console.error("-- deleteAssociation::error", error);
				}).catch( (error) => {
					console.error("handleAction:deleteAssociation:catch", error);
				});
				await (this as any).setLoadingData();
				this.$emit("update-table");
			} catch (error) {
				console.error("deleteAssociation", { error: error });
				await (this as any).setLoadingData();
			}
		},

		async handleEnableEdit(record: any) {
			record.edited = true;
			return record;
		},

		async handleConfirmEdit(record: any) {
			record.start_date = (this as any).moment(
				String(record.new_start_date)
			).format(DATE_TIME_FORMAT),
			record.end_date = (this as any).moment(
				String(record.new_end_date)
			).format(DATE_TIME_FORMAT),

			record.new_start_date = (this as any).moment(
				String(record.new_start_date)
			).format(DATE_TIME_FORMAT),
			record.new_end_date = (this as any).moment(String(record.new_end_date)).format(
				DATE_TIME_FORMAT
			),

			await (this as any).setLoadingData(TypeLoading.loading);
			await (this as any).updateAssociate(record);
			await (this as any).setLoadingData();
			return record;
		},

		async handleCancelEdit(record: any) {
			record.edited = false;
			record.start_date = (this as any).moment(record.new_start_date).format(DATE_TIME_FORMAT);
			record.end_date = (this as any).moment(record.new_end_date).format(DATE_TIME_FORMAT);
			record.new_start_date = undefined;
			record.new_end_date = undefined;
			return record;
		}, 

		async dispatchShowLineItem(id: any) {
			return await (this as any).$store.dispatch("creative/show", id, {
				root: true,
			});
		},

		async updateAssociate(record: any) {
			await (this as any).$store.dispatch(
				"creative/associateLineItemUpdate",
				record
			).then( (result) => {
				record.start_date = (this as any).moment(record.new_start_date).format(DATE_FORMAT),
				record.end_date = (this as any).moment(record.new_end_date).format(DATE_FORMAT),
				record.edited = false;
			},
			(error) => {
				console.error("-- updateAssociate::error", error);
			}).catch( (error) => {
				console.error("handleAction:updateAssociate:catch", error);
			});
		},
	},
});
